import { type TOperation } from 'src/@types';
import {
    type IAccessTokenResponse,
    type TAUthMeResponse,
    type TGetConfigResponse,
    type TGetDesktopGameDownloadLinkResponse,
    type THandleAuthenticatedModalToggleArguments,
    type TSetUpPushNotificationArguments,
} from 'src/@types/operations';

import * as Bridge from '../../bridge';
import { attributionHook, scopelyId, service, tracker } from '../../configs/web-sdk.config';
import { REACT_APP_S3, USER_LOGGED_IN } from '../../constants';
import { NOTIFICATIONS_STATES } from '../../constants/notifications.constants';
import { USER_DATA_SESSION_STORAGE_KEY } from '../../constants/project.constants';
import { googleAnalyticsEvent } from '../../helpers/GA4Helper';
import {
    isNotificationEnabledFromBrowser,
    isSystemHasPermission,
} from '../../helpers/pushNotification';
import { titanAnalyticsEvents } from '../../helpers/titanAnalytics';
import { generateDesktopDownloadLink } from '../../helpers/utils';
import { fetch, setSessionStorage } from '../../services';
import { getLocalStorage, removeLocalStorage, setLocalStorage } from '../../services';
import notificationsSlice from '../notifications';
import { globalSlice } from './globalSlice';

const user = {
    id: 12412,
    // avatar: avatar,
    name: 'Oblivion_220',
    level: '46 lvl',
    coinTitle: 'Web Coins',
    coinCount: '24.680.25k',
    regionId: 117,
    regionLang: 'EN',
    livingSkulls: 4292,
};

const setUpPushNotification = ({
    accountId,
    isNotificationAllowed,
    dispatch,
}: TSetUpPushNotificationArguments) => {
    const { setNotificationsState } = notificationsSlice.actions;
    if (isSystemHasPermission()) {
        if (isNotificationAllowed) {
            if (isNotificationEnabledFromBrowser()) {
                service?.start(accountId).then(() => {
                    dispatch(setNotificationsState(NOTIFICATIONS_STATES.ENABLED));
                });
            } else {
                service?.subscribeToPushNotifications(accountId).then((event) => {
                    if (event) {
                        titanAnalyticsEvents.generateChangeEvent({
                            interaction_object: 'notifications_permission',
                            screen_category: 'notifications',
                            screen_id: `notifications_permission_allowed`,
                        });
                        dispatch(setNotificationsState(NOTIFICATIONS_STATES.ENABLED));
                    } else {
                        titanAnalyticsEvents.generateChangeEvent({
                            interaction_object: 'notifications_permission',
                            screen_category: 'notifications',
                            screen_id: `notifications_permission_blocked`,
                        });

                        dispatch(setNotificationsState(NOTIFICATIONS_STATES.BLOCKED_FROM_BROWSER));
                    }
                });
            }
        } else {
            dispatch(setNotificationsState(NOTIFICATIONS_STATES.BLOCKED_FROM_CONTROLLER));
        }
    } else {
        dispatch(setNotificationsState(NOTIFICATIONS_STATES.UNAVAILABLE));
    }
};

const getAccessToken: TOperation<string, void> = (token) => {
    if (!token) {
        return () => new Promise((_, rej) => rej('Please provide token'));
    }

    const { setLoading, setAuthenticated, setSnackbar } = globalSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const { data } = await fetch<IAccessTokenResponse>({
                endPoint: '/auth/login',
                method: 'POST',
                body: {
                    token: token,
                },
            });

            if (data.firstVisit) {
                googleAnalyticsEvent.event({
                    action: 'scopelyID_register',
                    event_category: 'authorization',
                    event_label: 'first_time_visit',
                });
            }

            Bridge.saveSessionToken(data.accessToken);
            dispatch(setLoading(false));
            dispatch(setAuthenticated(true));
            setLocalStorage(USER_LOGGED_IN, '1');
        } catch (error) {
            dispatch(setLoading(false));
            if (401 !== error?.response?.status && 503 !== error?.response?.status) {
                dispatch(
                    setSnackbar({
                        open: true,
                        message: 'Oops! There was an error',
                        messageTranslationKey: 'common_snackbar_oops_error_text',
                        variant: 'error',
                    }),
                );
            }
        }
    };
};

const authMeAsync: TOperation = () => {
    const { setCurrentUser, setLoading, setAuthenticated } = globalSlice.actions;

    return async (dispatch) => {
        dispatch(setLoading(true));

        try {
            const { data } = await fetch<TAUthMeResponse>({
                headers: {
                    Authorization: `Bearer ${Bridge.getSessionToken()}`,
                },
                endPoint: '/auth/me',
                method: 'GET',
            });

            // TODO remove user mock
            const userData = {
                ...user,
                ...(data?.payload || {}),
                isNotificationAllowed: data?.isNotificationAllowed,
            };

            googleAnalyticsEvent.event({
                action: 'scopelyID_loggedin',
                event_category: 'authorization',
                event_label: 'authorization_success',
            });

            setSessionStorage(USER_DATA_SESSION_STORAGE_KEY, { ...userData });

            dispatch(setCurrentUser({ ...userData }));

            dispatch(setAuthenticated(true));
            dispatch(setLoading(false));

            if (userData.accountId) {
                tracker.getContext().setTenant(userData.accountId);

                setUpPushNotification({
                    accountId: userData.accountId,
                    isNotificationAllowed: userData.isNotificationAllowed,
                    dispatch,
                });
            }

            if (getLocalStorage(USER_LOGGED_IN)) {
                window?.DD_RUM?.addAction('logins-count', {
                    accountId: userData.accountId,
                });
                removeLocalStorage(USER_LOGGED_IN);
            }
        } catch (err) {
            dispatch(logout());
            dispatch(setLoading(false));
        }
    };
};

const logout: TOperation = () => async (dispatch) => {
    const { setCurrentUser, setAuthenticated, setLoading } = globalSlice.actions;
    service?.unsubscribeToPushNotifications();
    dispatch(setLoading(true));
    Bridge.removeSessionToken();
    removeLocalStorage(USER_DATA_SESSION_STORAGE_KEY);
    // setLocalStorage('userSeenEventIds', null); // TODO can be needed
    dispatch(setCurrentUser(null));
    dispatch(setAuthenticated(false));
    scopelyId.logout();
};

export const isAuthenticated = () => !!Bridge.getSessionToken() || false;

const handleAuthenticatedModalToggle: TOperation<THandleAuthenticatedModalToggleArguments> =
    ({ open, type }) =>
    (dispatch) => {
        const { setAuthenticatedModal } = globalSlice.actions;
        dispatch(setAuthenticatedModal({ open, type }));
    };

const handlePlayOnPCDialogData = (data) => (dispatch) => {
    const { setPlayOnPCDialogData } = globalSlice.actions;
    dispatch(setPlayOnPCDialogData(data));
};

const getConfigs: TOperation = () => {
    const { setConfigs } = globalSlice.actions;

    return async (dispatch) => {
        try {
            const { data } = await fetch<TGetConfigResponse>({
                endPoint: `/configs`,
                headers: {
                    'Cache-Control': 'no-store, no-cache, must-revalidate, proxy-revalidate, max-age=0',
                },
            });
            dispatch(setConfigs(data));
        } catch (err) {
            dispatch(
                setConfigs([
                    {
                        name: 'killSwitch',
                        value: {
                            global: false,
                        },
                    },
                ]),
            );
        }
    };
};

const setErrorModal = (data) => (dispatch) => {
    const { setErrorModal } = globalSlice.actions;
    dispatch(setErrorModal(data));
};

const handleOpenSnackbar = (data) => (dispatch) => {
    const { setSnackbar } = globalSlice.actions;

    titanAnalyticsEvents.generatePopUpEvent({
        screen_id: 'notification',
        screen_id_helper: `type_${data.variant}_message_${data.message}`,
        screen_category: 'notification',
        interaction_object: `notification_${data.message}_pop_up`,
    });

    dispatch(setSnackbar(data));
};

const handleCloseSnackbar: TOperation = () => (dispatch) => {
    const { setSnackbar } = globalSlice.actions;
    dispatch(setSnackbar({ open: false }));
};

const getDesktopGameDownloadLink: TOperation<'mac' | 'windows', string> = (
    linkType = 'windows',
) => {
    const { setSnackbar } = globalSlice.actions;

    const user_landing_id = attributionHook.getUserLandingId();

    let downloadLink = '';

    return async (dispatch) => {
        try {
            const { data } = await fetch<TGetDesktopGameDownloadLinkResponse>({
                url: 'https://launcher.xsolla.com',
                endPoint: `/otp?tracking_payload=${user_landing_id}`,
                method: 'POST',
            });
            if (data.otp) {
                downloadLink = generateDesktopDownloadLink(data.otp);
            }
        } catch (error) {
            dispatch(
                setSnackbar({
                    open: true,
                    message: 'Oops! There was an error', // TODO
                    messageTranslationKey: 'common_snackbar_oops_error_text',
                    variant: 'error',
                }),
            );
        }

        return downloadLink;
    };
};

export const globalOp = {
    authMeAsync,
    getAccessToken,
    handleCloseSnackbar,
    handleOpenSnackbar,
    logout,
    handlePlayOnPCDialogData,
    handleAuthenticatedModalToggle,
    getConfigs,
    setErrorModal,
    getDesktopGameDownloadLink,
};
